body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#text-entry-select>div>div>div>input,
::placeholder {
  color: #656576;
  margin-left: 0px;
  opacity: 1;
}

div#dropdown-1>div>div>div>input,
::placeholder {
  color: #005b97;
  margin-left: 0px;
  opacity: 1;
}

#text-entry-select>div>div>.react-dropdown-select-content>span {
  color: #656576;
  margin-left: 0px;
}

#text-entry-select>div>div:focus,
:focus-within {
  box-shadow: none !important;
}

.react-dropdown-select-dropdown-position-bottom {
  background: #ffffff !important;
  border: 1px solid #005b97 !important;
  box-sizing: border-box !important;
}

.react-dropdown-select-item {
  padding: 10px !important;
}

.react-dropdown-select-item-disabled {
  background-color: #ffffff !important;
  color: #656576 !important;
}

.react-dropdown-select-item-disabled ins {
  display: none !important;
}

.MuiInput-underline::after {
  transform: none !important;
  transition: none !important;
}

.MuiMenu-paper {
  transition: none !important;
  transition-duration: 0s !important;
  box-shadow: none !important;
}

.MuiList-root.MuiMenu-list {
  padding: 0px !important;
}

.react-calendar {
  border: 1px solid #005b97 !important;
}

select:focus-visible {
  outline: none !important;
}

.DateRangePickerInput {
  background: none;
}

.DateInput {
  background: none;
}

.DateInput_input {
  background: none;
  color: #005b97 !important;
}

.DateRangePickerInput__withBorder {
  border: none;
  background: none;
}

.DateInput__small {
  width: 65px;
}

.DateInput_input__small {
  font-size: 12px;
  padding: 0px !important;
}

.DateInput_input__focused {
  border: none;
  background: none;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  width: 100%;
  cursor: move;
}

.react-grid-item {
  min-width: 300px;
  min-height: 300px;
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item>.react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(.react-grid-placeholder) {
  background: white;
}